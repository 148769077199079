import React from 'react'
import { Link } from 'gatsby'

const Menu = ({ menu }) => (
  <nav className="sidebar-navigation">
    <ul className="list-none pl-0">
      {menu.map(item => (
        <li className="mb-3" key={item.path}>
          {item.path.startsWith('/Doge') ? (
            <a
              href={'//www.brendon1555.com' + item.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.label}
            </a>
          ) : (
            <Link to={item.path} className="" activeClassName="current-page">
              {item.label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </nav>
)

export default Menu
